export default {
    id: null,
    nome: null,
    validadeString: '30 dias',
    dataEmissao: null,
    dataVencimento: null,
    dataParaPedir: null,
    dataPedido: null,
    observacoes: null,
    urlRenovacao: null,
    status: 1,
    tipo: null,
    titular: null,
    active: true,
}
